<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">Доставка</div>
              
            </div>
          </el-col>

          <el-col :span="6" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            
            <el-button size="small" class="btu-color" @click="watchModal = true">
              {{ $t('Список доставок') }}
            </el-button>
            <el-button size="small" class="btu-color" @click="sendModal = true">
              {{ $t('Создать доставку') }}
            </el-button>
           
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->



        <div class="card-table-header table-crm-smart custom__scroll">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
                <thead>
                    <tr>
                        <th class="w50p">
                            ID
                        </th>
                        <th>
                            Название
                        </th>
                        <th>
                            Количество
                        </th>
                        <th>
                            Вес
                        </th>
                        <th>
                            Заявка ID
                        </th>
                        <th>
                            ФИО клиента
                        </th>
                        <th>
                            Страна отправления
                        </th>
                        <th>
                            Страна отправки
                        </th>

                        <th>
                            Страна прихода
                        </th>
                        <th>
                            Склад прихода
                        </th>
                    </tr>
                    <tr>
                        
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'№'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Название'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Адрес'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Адрес'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-date-picker
                                :placeholder="'Дата'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.id"
                                size="mini"
                                >
                            </el-date-picker>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'ФИО'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Страна отправления'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Страна отправки'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Страна прихода'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                        <th>
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="'Склад прихода'"
                                class="id_input w100"
                            ></el-input>
                        </th>
                    </tr>

                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr v-for="payment in inventory" :key="payment.id" class="cursor-pointer">
                        <td>
                            {{ payment.id }}
                        </td>
                        <td>
                            {{ payment.name }}
                        </td>
                        <td>
                            {{ payment.quantity }}
                        </td>
                        <td>
                            {{ payment.weight }}
                        </td>
                        <td>
                            {{ payment.orderNumber }}
                        </td>
                        <td>
                            {{ payment.fullName }}
                        </td>
                        <td>
                            {{ payment.sendCountry }}
                        </td>
                        <td>
                            {{ payment.sendWarehouse }}
                        </td>
                        <td>
                            {{ payment.arriveCountry }}
                        </td>
                        <td>
                            {{ payment.arriveWarehouse }}
                        </td>
                    </tr>
                </transition-group>
            </table>
            <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                <el-table :data="test">
                    <el-table-column prop="name" :label="$t('message.nomination')">
                        
                    </el-table-column>
                    <el-table-column prop="quantity" :label="$t('message.quantity')">
                        
                    </el-table-column>
                    <el-table-column prop="weight" :label="$t('message.measurement')">
                        
                    </el-table-column>
                    <el-table-column prop="sizes" :label="$t('message.dimension')">
                        
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('message.price')">
                        
                    </el-table-column>
                </el-table>
            </el-dialog>
            <div class="my___pagination">
            <crm-pagination
                :class="mode ? 'pagination__day' : 'pagination__night'"
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
            </div>
        </div>

        
        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false" class="bg-se"  :visible.sync="sendModal" size="85%" >
                <crm-send></crm-send>
            </el-drawer>
        </div>
        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false" class="bg-se"  :visible.sync="watchModal" size="85%" >
                <crm-inventory></crm-inventory>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";

import CrmSend from "./components/crm-pallet";
import CrmInventory from "./components/crm-inventory";

import {mapGetters} from 'vuex'

export default {
    name: "costTransactions",
    components: {
        Pagination,
        CrmSend,
        CrmInventory
    },

    data() {
        return {
            watchModal: false,
            createModal: false,
            dialogItemsVisible: false,
            currentPage2: 5,
            sendModal: false,
            options: [{
                value: 'Турция - Узбекистан',
                label: 'Турция - Узбекистан'
                }, {
                value: 'США - Узбекистан',
                label: 'США - Узбекистан'
                },],
            value: '',
            inventory: [
                {
                    id: 1,
                    name: 'Монитор LG',
                    quantity: '2',
                    weight: '8',
                    orderNumber: '432',
                    fullName: 'Мадина Якубова',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 2,
                    name: 'Наушники Sony',
                    quantity: '2',
                    weight: '1',
                    orderNumber: '43',
                    fullName: 'Бобурбек Хабибуллаев',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 3,
                    name: 'IPhone 12 pro',
                    quantity: '2',
                    weight: '2',
                    orderNumber: '672',
                    fullName: 'Илона Фозилжанова',
                    sendCountry: 'Китай',
                    sendWarehouse: 'Пекин',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 1,
                    name: 'Клавиатура Varmilo',
                    quantity: '5',
                    weight: '9',
                    orderNumber: '552',
                    fullName: 'Темур Кадиров',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'Iphone 12 pro max',
                    quantity: '2',
                    applied: '6'
                },
                {
                    id: 2,
                    name: 'Macbook pro 16 2020',
                    quantity: '2',
                    applied: '6'
                },
                {
                    id: 3,
                    name: 'Samsung Galaxy S22',
                    quantity: '2',
                    applied: '6'
                }
            ],
            checkList: [],
            activeType: "warehouse",
            form: {},
            test: [],
            sarcheBlocks: "",
            filterForm: {
                id: "",
                customer: "",
                paymentType:"",
                typePayment: "",
              
                time: "",
                sum: "",
                currency: "",
                created_at: "",
                updated_at: "",
            },
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                customer: {
                    show: true,
                    title: "Типы расхода",
                    sortable: true,
                    column: "customer",
                },

                paymentType: {
                    show: true,
                    title: "Тип платежа",
                    sortable: true,
                    column: "paymentType",
                },

                typePayment: {
                    show: true,
                    title: "Тип оплаты",
                    sortable: true,
                    column: "typePayment",
                },

               

                time: {
                    show: true,
                    title: "Время",
                    sortable: true,
                    column: "time",
                },
                
                sum: {
                    show: true,
                    title: "Сумма",
                    sortable: true,
                    column: "sum",
                },


                currency: {
                    show: true,
                    title: "Валюта ",
                    sortable: true,
                    column: "currency",
                },

                created_at: {
                    show: true,
                    title: "Дата создания",
                    sortable: true,
                    column: "created_at",
                },

                updated_at: {
                    show: true,
                    title: "Дата изменена ",
                    sortable: true,
                    column: "updated_at",
                },

                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
    methods: {
        handleClick(type) {
        this.activeType = type;
            if (this.genderstatistic) {
                this.changeStatistics();
            }
        },
        showItems(model) {
            this.test = model
            this.dialogItemsVisible = true;
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE"
        }),
    }
};
</script>
<style>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
</style>
