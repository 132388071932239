<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('Список') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="24">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div
                            class="
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div class="mb-4 flex__spacecenter">
                                <select-cargo
                                    class="w50 pallet__flex mr-4 "
                                    :size="'medium'"
                                    :placeholder="'Выбрать груз'"
                                    :id="value1"
                                    :board_id="value1"
                                    v-model="value1"
                                >
                                </select-cargo>
                                <select-warehouse
                                    class="w50 pallet__flex mr-4 "
                                    :size="'medium'"
                                    :placeholder="'Выбрать склад'"
                                    :id="value1"
                                    :board_id="value1"
                                    v-model="value1"
                                >
                                </select-warehouse>
                                <div class="ml-4">
                                    <el-button size="medium" type="success" plain>Сканировать</el-button>
                                </div>
                            </div>
                            <div class="">
                                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
                                    <thead>
                                        <tr>
                                            <th class="w50p">
                                                ID
                                            </th>
                                            <th>
                                                Название
                                            </th>
                                            <th>
                                                Количество
                                            </th>
                                            <th>
                                                Вес
                                            </th>
                                            <th>
                                                Заявка ID
                                            </th>
                                            <th>
                                                ФИО клиента
                                            </th>
                                            <th>
                                                Страна отправления
                                            </th>
                                            <th>
                                                Страна отправки
                                            </th>
                                        </tr>
                                        <tr>
                                            
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'№'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'Название'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'Адрес'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'Адрес'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            <th>
                                                <el-date-picker
                                                    :placeholder="'Дата'"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    v-model="filterForm.id"
                                                    size="mini"
                                                    >
                                                </el-date-picker>
                                            </th>
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'ФИО'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'Страна отправления'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                            <th>
                                                <el-input
                                                    clearable
                                                    size="mini"
                                                    v-model="filterForm.id"
                                                    :class="mode ? 'filter__day' : 'filter__night'"
                                                    :placeholder="'Страна отправки'"
                                                    class="id_input w100"
                                                ></el-input>
                                            </th>
                                        </tr>

                                    </thead>

                                    <transition-group name="flip-list" tag="tbody">
                                        <tr v-for="payment in inventory" :key="payment.id" class="cursor-pointer">
                                            <td>
                                                {{ payment.id }}
                                            </td>
                                            <td>
                                                {{ payment.name }}
                                            </td>
                                            <td>
                                                {{ payment.quantity }}
                                            </td>
                                            <td>
                                                {{ payment.weight }}
                                            </td>
                                            <td>
                                                {{ payment.orderNumber }}
                                            </td>
                                            <td>
                                                {{ payment.fullName }}
                                            </td>
                                            <td>
                                                {{ payment.sendCountry }}
                                            </td>
                                            <td>
                                                {{ payment.sendWarehouse }}
                                            </td>
                                        </tr>
                                    </transition-group>
                                </table>
                            </div>
                            <div class="mt-4 tracking__flex">
                                <span class="tracking__light">Выбрать машину</span>
                                <select-car
                                    class="w50 pallet__flex mt-2 mr-4 "
                                    :size="'medium'"
                                    :placeholder="'Выбрать машину'"
                                    :id="value1"
                                    :board_id="value1"
                                    v-model="value1"
                                >
                                </select-car>
                                <span class="tracking__light mt-4">Выбрать курьера</span>
                                <select-driver
                                    class="w50 mt-2 pallet__flex"
                                    :size="'medium'"
                                    :placeholder="'Выбрать курьера'"
                                    :id="value1"
                                    :board_id="value1"
                                    v-model="value1"
                                >
                                </select-driver>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
       
    </div>
</template>

<script>
import selectBoard from "@/components/inventory/select-deal-board";
import SelectCargo from "@/components/inventory/select-cargo";
import SelectWarehouse from "@/components/inventory/select-warehouse";
import SelectDriver from "@/components/inventory/select-driver";
import SelectCar from "@/components/inventory/select-transport";

export default {
data: () => ({
    formLabelAlign: {
        name: '',
        region: '',
        type: ''
    },
    mode: true,
    value1: '',
    createModal: false,
    value2: '',
    filterForm: {
            id: "",
            customer: "",
            paymentType:"",
            typePayment: "",
        
            time: "",
            sum: "",
            currency: "",
            created_at: "",
            updated_at: "",
    },
    columns: {
            id: {
                show: true,
                title: "№",
                sortable: true,
                column: "id",
            },

            customer: {
                show: true,
                title: "Типы расхода",
                sortable: true,
                column: "customer",
            },

            paymentType: {
                show: true,
                title: "Тип платежа",
                sortable: true,
                column: "paymentType",
            },

            typePayment: {
                show: true,
                title: "Тип оплаты",
                sortable: true,
                column: "typePayment",
            },

            

            time: {
                show: true,
                title: "Время",
                sortable: true,
                column: "time",
            },
            
            sum: {
                show: true,
                title: "Сумма",
                sortable: true,
                column: "sum",
            },


            currency: {
                show: true,
                title: "Валюта ",
                sortable: true,
                column: "currency",
            },

            created_at: {
                show: true,
                title: "Дата создания",
                sortable: true,
                column: "created_at",
            },

            updated_at: {
                show: true,
                title: "Дата изменена ",
                sortable: true,
                column: "updated_at",
            },

            settings: {
                show: true,
                title: "Профиль",
                sortable: false,
                column: "settings",
            },
    },
    tableData: [{
        date: 'Монитор Dell',
        name: '2',
        address: '3.8',
        code: '67804289323'
        }, {
        date: 'Монитор LG',
        name: '3',
        address: '2.8',
        code: '67804289323'
    },],
    inventory: [
                {
                    id: 1,
                    name: 'Монитор LG',
                    quantity: '2',
                    weight: '8',
                    orderNumber: '432',
                    fullName: 'Мадина Якубова',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 2,
                    name: 'Наушники Sony',
                    quantity: '2',
                    weight: '1',
                    orderNumber: '43',
                    fullName: 'Бобурбек Хабибуллаев',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 3,
                    name: 'IPhone 12 pro',
                    quantity: '2',
                    weight: '2',
                    orderNumber: '672',
                    fullName: 'Илона Фозилжанова',
                    sendCountry: 'Китай',
                    sendWarehouse: 'Пекин',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
                {
                    id: 1,
                    name: 'Клавиатура Varmilo',
                    quantity: '5',
                    weight: '9',
                    orderNumber: '552',
                    fullName: 'Темур Кадиров',
                    sendCountry: 'США',
                    sendWarehouse: 'Нью-Йорк',
                    arriveCountry: 'Узбекистан',
                    arriveWarehouse: 'Ташкент'
                },
            ],
}),
components: {
    selectBoard,
    SelectCargo,
    SelectDriver,
    SelectCar,
    SelectWarehouse
}
}
</script>

<style>

</style>
 